import React from 'react'

export default function (props) {

  const themeClass = props.darkMode ? "dark resume" : "resume"

  return (
    <div className={ themeClass }>
        <div className='right__text'>
            <h1>Resume</h1>
        </div>
        <div className='resume__part'>
            <div className='resume__container'>
                <div className="resume__img" style={{backgroundImage: `url("/assets/images/Chaste_Gikundiro_resume.png")`}}></div>
                <a href="assets/images/Chaste_resume.pdf" download>Download</a>
            </div>
        </div>
    </div>
  )
}