import React, { useState,useEffect } from 'react'
import Navbar from './components/Navbar'
import BottomNavBar from './components/BottomNavBar'
import Greetings from './components/Greetings'
import Skills from './components/Skills'
import Projects from './components/Projects'
import Resume from './components/Resume'
import AboutMe from './components/AboutMe'
import Footer from './components/Footer'


export default function App() {

  const [ darkMode, setDarkMode ] = useState(false)

  function toggleDarkMode() {
    setDarkMode(prevMode => !prevMode)

  }


  useEffect(() => {
    const toggleSound = new Audio("/assets/sounds/sound_ex_machina_Button_Blip.mp3")
    toggleSound.play()
    document.body.style.background = darkMode ? "#242424 url('/assets/images/background-image-dark.png')" : "#fff url('/assets/images/background-image.png')"
    
  }, [darkMode])


  return (
    <div className='container'>

      

      <Navbar
       darkMode = { darkMode } 
       toggleDarkMode = { toggleDarkMode }
       />

       <BottomNavBar
       darkMode = { darkMode } 
       />

      <Greetings 
      darkMode = { darkMode }
      />

      <Skills 
      darkMode = { darkMode }
      />

      <Projects
      darkMode = { darkMode }
      />

      <Resume 
      darkMode = { darkMode }
      />

      <AboutMe 
      darkMode = { darkMode }
      />

      <Footer 
      darkMode = { darkMode }
      />
    </div>
    
  )
}




