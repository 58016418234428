import React from 'react'
import skillsData from './skillsData'
import SkillCards from './SkillCards'


export default function Skills(props) {

  const SkillCard = skillsData.map(data => {

    return <SkillCards 
             key = { data.key }
             {...data}
     />
    
  })

  const themeClass = props.darkMode ? "dark skills" : "skills"

  return (
    <div className={ themeClass }>
        <div className='right__text'>
            <h1>What I do</h1>
        </div>

        { SkillCard }
    </div>
  )
}
