import React from 'react'

export default function Navbar(props) {

  const themeImg = props.darkMode ? "/assets/images/sun.png" : "/assets/images/moon.png"

  const themeClass = props.darkMode ? "dark" : ""

  
  return (
      <div className={ themeClass }>
      <header>
       <h1>ChAsTe</h1>
       
       <div className='theme' onClick={props.toggleDarkMode}>
         <img src={ themeImg } alt="Dark mode" />
       </div>
       
    </header>
    </div>
  )
}
