import { ThemeContext } from '@emotion/react'
import React from 'react'

export default function AboutMe(props) {

  const themeClass = props.darkMode ? "dark about" : "about"

  const themeImg = props.darkMode ? "/assets/images/profile-pic.png" : "/assets/images/profile-pic-light.png"

  return (
    <div className={ themeClass }>
        <div className="right__text">
            <h1>About Me</h1>
        </div>
        <div className="about__me">
            <img src={ themeImg } alt="" />
            <div className='about__me__text'>
            <p>Names: Chaste Gikundiro</p>
            <hr/>
            <p>Age: 20</p>
            <hr/>
            <p>hobbies & interest: 📚 Reading, 🚴‍♂️ Biking, 🎮 Gaming, 🪙 Cyrpto, 🎸 Music</p>
            </div>

        </div>


    </div>
  )
}
