import React from 'react'

export default function Projects(props) {

  const themeClass = props.darkMode ? "dark projects" : "projects"

  return (
    <div className={ themeClass }>
         <div className="right__text">
            <h1>Projects</h1>
        </div>

        <div className="peerlist">
            <img src="/assets/images/peerlist.png" alt="peerlist" />
            <a href="https://peerlist.io/chaste">Check my peerlist profile  {">>>"} </a>

        </div>
    </div>
  )
}
