import React from 'react'

export default function Greetings(props) {

  const themePic = props.darkMode ? {backgroundImage: `url("/assets/images/profile-pic.png")`} : {backgroundImage: `url("/assets/images/profile-pic-light.png")`}

  const themeClass = props.darkMode ? "dark greetings" : "greetings"



  return (
    <div className={ themeClass }>
        <div className='section__1 hello'>
            <h1>Hi👋, I am Chaste, I am Software developer.</h1>
        </div>

        <div className='section__1 picture'>
        <img src="/assets/images/peace-hand.png" alt="peace-hand" />

          <div className='my__picture' style={themePic}></div>
</div>

    </div>
  )
}
