import React from 'react'

export default function SkillCards(props) {

  const skills = props.skills.map(skill => {
    return (
      <div className='skill'>
          <img src={ skill.logo } alt="" width={ 100 }/>
          <h3>{ skill.name }</h3>
      </div>
    )
  })
   

  return (
    <div className='skill__cards'>
            <div className='card'>
                <h1>{ props.skillType }</h1>
                <div className='skillsList'>
                { skills }
                </div>
                
            </div>
        </div>
  )
}




