import React from 'react'

export default function Footer(props) {

  const themeClass = props.darkMode ? "dark footer" : "footer"

  return (
    <div className={ themeClass }>
        <a href="mailto:gikundirochaste@gmail.com">Get in Touch</a>       
        <h1 style={{color:props.darkMode ? "#d8d8d8" : "#2c2c2c"}}>ADIOS! 👋</h1>
        <p style={{color:props.darkMode ? "#d8d8d8" : "#2c2c2c"}}>Made with 🖤 by me.</p>
    </div>
  )
}
