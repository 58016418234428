export default [
    {
        id: 1,
        skillType: 'Web Development',
        skills: [
            {
                logo: '/assets/images/html.png',
                name: 'HTML'
            },
            {
                logo: '/assets/images/css.png',
                name:'CSS'
            },
            {
                logo: '/assets/images/javascript.png',
                name: 'Javascript'
            },
            {
                logo: '/assets/images/reactjs.png',
                name: 'React JS'
            },
            {
                logo: '/assets/images/nodejs.png',
                name: 'Node JS'
            },
        ]
    
    },
    {
        id: 2,
        skillType: 'Mobile Development',
        skills: [
            {
                logo: '/assets/images/flutter.png',
                name: 'Flutter'
            },
            {
                logo: '/assets/images/dart.png',
                name: 'Dart'
            },
        ]
    
    },


]