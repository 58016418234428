import React from 'react'

export default function BottomNavBar() {
  return (
    <div>
        {/* <nav>
           <div className='links'>
              <div><a href="#">Home/</a></div>
              <div><a href="#">Skills/</a></div>
              <div><a href="#">Projects/</a></div>
              <div><a href="#">Resume/</a></div>
              <div><a href="#">About me/</a></div>
           </div>  
       </nav> */}
    </div>
  )
}
